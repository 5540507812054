<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-fluid w-full">
                <div class="font-medium text-xl text-900 mb-3">
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="nao-conformidade-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                </div>
                <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                <div :style="loading ? 'opacity: 30%;' : ''">
                    <Toast />
                    <div class="">
                        <div class="field">
                            <div class="field col-12">
                                <label for="descricao">Descrição</label>
                                <Textarea
                                    class="w-full"
                                    v-model="form.descricao"
                                    :autoResize="true"
                                    rows="5"
                                    placeholder="Digite aqui..."
                                    :class="{ 'p-invalid': submitted && !form.descricao }"
                                />
                            </div>
                            <small class="p-error" v-if="submitted && !form.descricao">Descrição é obrigatória.</small>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/nao-conformidade')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import AreaMedicaService from '../../services/AreaMedicaService';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            loading: false
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new AreaMedicaService('/tipo_nao_conformidade');
        try {
            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
            }
        } catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    methods: {
        async save() {
            this.submitted = true;
            try {
                await this.$service.save(this.form);
                this.$toast.add({ severity: 'success', summary: 'Não Conformidade salva com sucesso!', life: 3000 });
                this.$router.replace('list');
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar a Não Conformidade!', life: 3000 });
            }
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar Não Conformidade' : 'Adicionar Não Conformidade';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    components: { AppInfoManual }
};
</script>

